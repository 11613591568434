p {
	margin-block-start: 0;
	margin-block-end: 0;
}

@font-face {
	font-family: Abstract-Thin;
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/abstract/LosAndes-AbstractThin.otf');
}

@font-face {
	font-family: Abstract-Light;
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/abstract/LosAndes-AbstractLight.otf');
}

@font-face {
	font-family: Abstract;
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/abstract/LosAndes-AbstractRegular.otf');
}

@font-face {
	font-family: Abstract;
	font-style: italic;
	font-weight: normal;
	src: url('../fonts/abstract/LosAndes-AbstractRegularIt.otf');
}

@font-face {
	font-family: Abstract;
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/abstract/LosAndes-AbstractSemiBold.otf');
}

@font-face {
	font-family: Abstract;
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/abstract/LosAndes-AbstractBold.otf');
}

@font-face {
	font-family: 'PSans0.8';
	src: url('../fonts/PhantomSans0.8/PhantomSans08-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'PSans0.8';
	src: url('../fonts/PhantomSans0.8/PhantomSans08-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PSans0.8';
	src: url('../fonts/PhantomSans0.8/PhantomSans08-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'PSans0.8';
	src: url('../fonts/PhantomSans0.8/PhantomSans08-MedItalic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}
